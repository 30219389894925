document.onclick = function (e) {
  if (e.target.classList.contains('js-preview-image')) {
    e.target.style.display = 'none';
    e.target.nextElementSibling.tabIndex = 0;

    if(e.target.parentElement.classList.contains('js-embed-video')) {
      e.target.nextElementSibling.src += "&autoplay=1";
    } else {
      e.target.nextElementSibling?.play();
    }
  }
};
